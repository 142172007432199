import React from 'react';

class CompanySidebarImage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
      return(
        <img src={this.props.image} className="mb-1" alt={this.props.alt} />

      );
    }
  }

  export default CompanySidebarImage;
