import React from 'react';
import quality from '../../images/Mission/quality-sheet-1.jpg';
import health from '../../images/Mission/h&s.jpg';
import environmental from '../../images/Mission/environmental.jpg';
import qualitylarge from '../../images/Mission/qualityr.jpg';
import healthlarge from '../../images/Mission/h&sr.jpg';
import environmentallarge from '../../images/Mission/environmentalr.jpg';
import { SRLWrapper } from "simple-react-lightbox";
import MissionSidebarImage from './MissionSidebarImage';

const options = {
    caption: {
      showCaption: false
    },
    thumbnails: {
        showThumbnails: false
    },
    buttons: {
        showAutoplayButton: false,
        showDownloadButton: false,
        showFullscreenButton: false,
        showThumbnailsButton: false
    }
  }

  const callbacks = {
    onLightboxOpened: object => console.log(object),
    onCountSlides: object => console.log(object)
  };

class MissionSidebarContent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
      return(
        <SRLWrapper options={options} callbacks={callbacks}>
            <MissionSidebarImage image={quality} imagelink={qualitylarge} alt="Quality Policy document" text="Quality Policy Click to view" />
            <MissionSidebarImage image={health} imagelink={healthlarge} alt="Health and safety document" text="Health & Safety Click to view" />
            <MissionSidebarImage image={environmental} imagelink={environmentallarge} alt="Environmetal document" text="Environmental Click to view" />
        </SRLWrapper>
      );
    }
  }

  export default MissionSidebarContent;
