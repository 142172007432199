import React from 'react';

class AboutSidebarContent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
      return(
        <div>
            <h1 className="font-bold text-base">Welcome to MSI</h1> 
                <p>MS INTERNATIONAL plc is a public company quoted on the London Stock Exchange with three operating divisions.</p>
                <p className="text-red-1000 font-bold mt-6">Defence <span className="text-white">-</span><a className="hover:text-gray-1000" href="http://www.msi-dsl.com/" target="_blank">www.msi-dsl.com</a></p>
                <p>Naval weapons, tactical and navigation equipment</p>
                <p className="text-red-1000 font-bold">Forging</p>
                <p>Steel forging for many applications</p>
                <p className="text-red-1000 font-bold">Petrol Station Superstructures - <a className="hover:text-gray-1000" href="http://www.global-msi.com/" target="_blank">www.global-msi.com</a></p>
                <p>Canopies - Shops and Car Washes.</p>
                <p className="mt-3 border-white border inline-block p-2 pr-16 ml-3">Factfile</p>
                <ul className="mt-2 ml-7 list-none">
                    <li className="mb-2">Group sales amount to some £50m per annum</li>
                    <li className="mb-2">Strong balance sheet - supports growth</li>
                    <li className="mb-2">Positive cash flow - supports investment</li>
                    <li className="mb-2">Exports account for some 60% of group sales</li>
                    <li className="mb-2">375 employees</li>
                    <li className="mb-2">Strong commitment to a comprehensive expansion and investment programme</li>
                </ul>
        </div>
      );
    }
  }

  export default AboutSidebarContent;
