import React from 'react';
import About from '../../components/AboutSidebarContent/AboutSidebarContent';
import Company from '../../components/CompanySidebarContent/CompanySidebarContent';
import Production from '../../components/ProductionSidebarContent/ProductionSidebarContent';
import Mission from '../../components/MissionSidebarContent/MissionSidebarContent';
import Values from '../../components/ValuesSidebarContent/ValuesSidebarContent';
import Products from '../../components/ProductSidebarContent/ProductSidebarContent';

class RightBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            about: false,
            overview: false,
            production: false,
            mission: false,
            values: false,
            products: false
        };
    }

    componentDidMount() {
        let Urlname = window.location.pathname;

        if (Urlname == '/about') {
            this.setState({about: true});
        } else if (Urlname == '/overview') {
            this.setState({overview: true});
        } else if (Urlname == '/production') {
            this.setState({production: true});
        } else if (Urlname == '/our-mission') {
            this.setState({mission: true});
        } else if (Urlname == '/values') {
            this.setState({values: true});
        } else if (Urlname == '/products') {
            this.setState({products: true});
        }
    }

    render() {
      return(
        <div className="pt-8 px-2 leading-3 absolute flex justify-center w-full">
            { this.state.about ? <About /> : null }
            { this.state.overview ? <Company /> : null }
            { this.state.production ? <Production /> : null }
            { this.state.mission ? <Mission /> : null }
            { this.state.values ? <Values /> : null }
            { this.state.products ? <Products /> : null }
        </div>
      );
    }
  }

  export default RightBar;
