import React from 'react';

class MissionSidebarImage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
      return(
        <div className="mb-3 ml-1 flex items-center">
            <a className="flex items-center" href={this.props.imageLink}>
                <img src={this.props.image} alt={this.props.alt} />
                <p className="ml-2">{this.props.text}</p>
            </a>
        </div>
      );
    }
  }

  export default MissionSidebarImage;
