import React from 'react';
import overview1 from '../../images/Company/overview1.jpg';
import overview2 from '../../images/Company/overview2.jpg';
import overview3 from '../../images/Company/overview3.jpg';
import overview4 from '../../images/Company/overview4.jpg';
import CompanySidebarImage from './CompanySidebarImage';

class CompanySidebarContent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
      return(
        <div>
            <CompanySidebarImage image={overview1} alt="Two workers forging metal" />
            <CompanySidebarImage image={overview2} alt="Working with a large machine" />
            <CompanySidebarImage image={overview3} alt="Working with a large machine" />
            <CompanySidebarImage image={overview4} alt="Inspecting the final product" />
        </div>
      );
    }
  }

  export default CompanySidebarContent;
