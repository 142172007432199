import React from 'react';
import production1 from '../../images/Production/production1.jpg';
import production2 from '../../images/Production/production2.jpg';
import production3 from '../../images/Production/production3.jpg';
import production4 from '../../images/Production/production4.jpg';
import production5 from '../../images/Production/production5.jpg';
import production6 from '../../images/Production/production6.jpg';
import production7 from '../../images/Production/production7.jpg';
import production1large from '../../images/Production/production1r.jpg';
import production2large from '../../images/Production/production2r.jpg';
import production3large from '../../images/Production/production3r.jpg';
import production4large from '../../images/Production/production4r.jpg';
import production5large from '../../images/Production/production5r.jpg';
import production6large from '../../images/Production/production6r.jpg';
import production7large from '../../images/Production/production7r.jpg';
import ProductionSidebarImage from './ProductionSidebarImage';
import { SRLWrapper } from "simple-react-lightbox";

const options = {
    caption: {
      showCaption: false
    },
    thumbnails: {
        showThumbnails: false
    },
    buttons: {
        showAutoplayButton: false,
        showDownloadButton: false,
        showFullscreenButton: false,
        showThumbnailsButton: false
    }
  }

class ProductionSidebarContent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
      return(
        <SRLWrapper options={options}>
            <div className="grid grid-cols-2">
                <div className="col-span-1">
                    <ProductionSidebarImage image={production1} imageLink={production1large} alt="Working at a computer" />
                    <ProductionSidebarImage image={production3} imageLink={production3large} alt="A piece of machinery close up" />
                    <ProductionSidebarImage image={production5} imageLink={production5large} alt="Using a machine to QA the product" />
                    <ProductionSidebarImage image={production7} imageLink={production7large} alt="The finshed product" />
                </div>
                <div className="col-span-1 ml-1">
                    <ProductionSidebarImage image={production2} imageLink={production2large} alt="Working in the warehouse" />
                    <ProductionSidebarImage image={production4} imageLink={production4large} alt="Worker looking over the warehouse" />
                    <ProductionSidebarImage image={production6} imageLink={production6large} alt="Measuring up two points of metal" />
                </div>
            </div>
        </SRLWrapper>
      );
    }
  }

  export default ProductionSidebarContent;
