import React from 'react';

import Header from '../../components/Header/Header';
import LeftMenu from '../../components/LeftMenu/LeftMenu';
import TopMenu from '../../components/TopMenu/TopMenu';
import MenuImage from '../../components/MenuImage/MenuImage';
import MainImage from '../../components/MainImage/MainImage';
import RightBar from '../../components/RightBar/RightBar';
import SimpleReactLightbox from "simple-react-lightbox";

function Layout({children}) {
  return (
    <SimpleReactLightbox>
        <div className="container mx-auto bg-[url('../images/Layout/black-bg.jpg')] bg-bottom">
            <Header />
            <div className="grid grid-cols-4 bg-[url('../images/Layout/sidebar.jpg')] bg-[right_top_26px] bg-no-repeat">
                <div className="col-span-1">
                    <MenuImage />
                    <LeftMenu />
                </div>
                <div className="col-span-3">
                    <div className="grid grid-cols-1">
                        <TopMenu />
                        <div className="grid grid-cols-3">
                            <div className="col-span-2">
                                <MainImage />
                            </div>
                            <div className="col-span-1 relative">
                                <RightBar />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-3 bg-[url('../images/Layout/content-bg.jpg')] bg-no-repeat">
                    {children}
                </div>
            </div>
        </div>
    </SimpleReactLightbox>
  );
}

export default Layout;
