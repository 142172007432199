import React from 'react';
import product1 from '../../images/Products/1-small.jpg';
import product2 from '../../images/Products/2-small.jpg';
import product3 from '../../images/Products/3-small.jpg';
import product4 from '../../images/Products/4-small.jpg';
import product5 from '../../images/Products/5-small.jpg';
import product6 from '../../images/Products/6-small.jpg';
import product7 from '../../images/Products/7-small.jpg';
import product1large from '../../images/Products/1.jpg';
import product2large from '../../images/Products/2.jpg';
import product3large from '../../images/Products/3.jpg';
import product4large from '../../images/Products/4.jpg';
import product5large from '../../images/Products/5.jpg';
import product6large from '../../images/Products/6.jpg';
import product7large from '../../images/Products/7.jpg';
import { SRLWrapper } from "simple-react-lightbox";
import ProductSidebarImage from './ProductSidebarImage';

const options = {
    caption: {
      showCaption: false
    },
    thumbnails: {
        showThumbnails: false
    },
    buttons: {
        showAutoplayButton: false,
        showDownloadButton: false,
        showFullscreenButton: false,
        showThumbnailsButton: false
    }
  }

class ProductSidebarContent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
      return(
        <SRLWrapper options={options}>
            <ProductSidebarImage image={product1} imageLink={product1large} alt="Product 1" />
            <ProductSidebarImage image={product2} imageLink={product2large} alt="Product 2" />
            <ProductSidebarImage image={product3} imageLink={product3large} alt="Product 3" />
            <ProductSidebarImage image={product4} imageLink={product4large} alt="Product 4" />
            <ProductSidebarImage image={product5} imageLink={product5large} alt="Product 5" />
            <ProductSidebarImage image={product6} imageLink={product6large} alt="Product 6" />
            <ProductSidebarImage image={product7} imageLink={product7large} alt="Product 7" />
        </SRLWrapper>
      );
    }
  }

  export default ProductSidebarContent;
