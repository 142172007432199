import React from 'react';
import core1 from '../../images/Values/core1.jpg';
import core2 from '../../images/Values/core2.jpg';
import core1large from '../../images/Values/core1r.jpg';
import core2large from '../../images/Values/core2r.jpg';
import { SRLWrapper } from "simple-react-lightbox";
import ValuesSidebarImage from './ValuesSidebarImage';

const options = {
    caption: {
      showCaption: false
    },
    thumbnails: {
        showThumbnails: false
    },
    buttons: {
        showAutoplayButton: false,
        showDownloadButton: false,
        showFullscreenButton: false,
        showThumbnailsButton: false
    }
  }

class ValuesSidebarContent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
      return(
        <SRLWrapper options={options}>
            <ValuesSidebarImage image={core1} imageLink={core1large} alt="Core 1" />
            <ValuesSidebarImage image={core2} imageLink={core2large} alt="Core 2" />
        </SRLWrapper>
      );
    }
  }

  export default ValuesSidebarContent;
