import React from 'react';

class ProductSidebarImage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
      return(
        <a href={this.props.imageLink}>
            <img src={this.props.image} className="mb-3" alt={this.props.alt} />
        </a>
      );
    }
  }

  export default ProductSidebarImage;
