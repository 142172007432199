import React from 'react';
import Naval from '../../images/MainPageImage/7297naval-weapons.jpg';
import Contact from '../../images/MainPageImage/9117contact.jpg';
import Company from '../../images/MainPageImage/0565companyoverview.jpg';
import Production from '../../images/MainPageImage/9918production.jpg';
import Mission from '../../images/MainPageImage/9088ourmission.jpg';
import Values from '../../images/MainPageImage/3169corevalues.jpg';
import Products from '../../images/MainPageImage/6671products.jpg';
import Approvals from '../../images/MainPageImage/1656forging.jpg';

class MainImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contact: false,
            about: false,
            company: false,
            production: false,
            mission: false,
            values: false,
            products: false,
            approvals: false
        };
    }

    componentDidMount() {
        let Urlname = window.location.pathname;

        if (Urlname == '/contact') {
            this.setState({contact: true});
        } else if (Urlname == '/about') {
            this.setState({about: true});
        } else if (Urlname == '/overview') {
            this.setState({company: true});
        } else if (Urlname == '/production') {
            this.setState({production: true});
        } else if (Urlname == '/our-mission') {
            this.setState({mission: true});
        } else if (Urlname == '/values') {
            this.setState({values: true});
        } else if (Urlname == '/products') {
            this.setState({products: true});
        } else if (Urlname == '/approvals') {
            this.setState({approvals: true});
        }
    }

    render() {
     const contact = this.state.contact;
     const about = this.state.about;
     const company = this.state.company;
     const production = this.state.production;
     const mission = this.state.mission;
     const values = this.state.values;
     const products = this.state.products;
     const approvals = this.state.approvals;

     return(
        <>
            { contact ? <img src={Contact} alt="MSI building" /> : null }
            { about ? <img src={Naval} alt="Worker working with a machine" /> : null }
            { company ? <img src={Company} alt="Worker looking over a production warehouse" /> : null }
            { production ? <img src={Production} alt="Workers carry large tubes of metal" /> : null }
            { mission ? <img src={Mission} alt="Worker looking through a microscope" /> : null }
            { values ? <img src={Values} alt="Two workers foring a piece of metal" /> : null }
            { products ? <img src={Products} alt="Metal in the furnace" /> : null }
            { approvals ? <img src={Approvals} alt="Worker inspecting a finsihed product" /> : null }
        </>
      );
    }
  }

  export default MainImage;
